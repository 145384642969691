import Vue from "vue";
import Auth from "@/lib/Auth";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    meta: {
      auth: false,
    },
    component: () => import("@/layouts/BaseApp.vue"),
    children: [
      {
        path: "",

        component: Home,
        meta: {
          title: "Cardápio",
        },
      },
    ],
  },

  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!Auth.isLogged()) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " - " + process.env.VUE_APP_NAME;
  }
});

export default router;
