
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "Dashboard",

  data() {
    return {
      dialogProdutoDetalhe: false,
      ModuloInfo: [],
      loadingData: true,
      dadosUsuario: {},
      lang: navigator.language,
      errors: [],
      rand: 1,
      moment: moment,
      isMobile: false,
      permissoes: {},
      tab: null,
      detalheProduto: {
        id: "3",
        name: "Tábua de Frios",
        slug: "tabua-de-frios",
        description: "Mussarela / copa / presunto",
        sizes: {
          large: {
            active: true,
            price: "25,00",
          },
          medium: {
            active: true,
            price: "35,00",
          },
          small: {
            active: true,
            price: "45,00",
          },
        },
        price: "0,00",
        hasVariation: true,
        image:
          "http://apiarenachopp-dev:83/userfiles/uploads/thumbs/fe1cc052768506ca_1.png",
      },
      currentItem: "tab-Web",
      tabitens: [],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  created() {
    // Overlay.show();
    this.getMenu();
    this.isMobile = this.detectMobile();
  },

  methods: {
    getMenu() {
      Overlay.show();

      this.loadingData = true;

      Api.get("/cardapio")
        .then((response) => {
          this.tabitens = response.data.body.cardapio;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    abreProduto(produto: any) {
      this.dialogProdutoDetalhe = true;
      this.detalheProduto = produto;
      console.log(produto);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
});
